.cursor-pointer {
  cursor: pointer;
}
.black {
  color: $black !important;
}
.primary-text-color {
  color: $primary-text-color !important;
}
.ml-auto {
  margin-left: auto;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.p-40 {
  padding: 40px;
}
.w-100 {
  width: 100%;
}
h1.title1 {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}
h6.title6 {
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
}
p.sub-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: $secondary-text-color;
}
.link {
  font-weight: 700 !important;
  color: $primary-brand-color !important;
  transition: all 0.3s ease;
  &:hover {
    color: darken($primary-brand-color, 10%);
    // color: $primary-brand-color-dark;
  }
}
.section-body {
  height: calc(100vh - 47px);
  background: $background-color;
  // padding: 25px 0;
  overflow-y: auto;
}
.bread-crumbs {
  a {
    font-weight: bold;
    color: #000000;
    &:not(:last-child) {
      &::after {
        content: "/";
        margin: 5px;
      }
    }
    &.active {
      color: $primary-brand-color;
      pointer-events: none;
    }
  }
}
.card {
  min-height: 275px;
  background: $white;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}
.label-value-wrap {
  .label {
    color: $grey;
    margin-bottom: 6px;
  }
  .value {
    font-size: 16px;
    line-height: 19px;
  }
}
.password-strength-text {
  li {
    color: $grey;
    &::before {
      content: "\E40C";
      display: inline-block;
      color: #ccc;
      margin-right: 5px;
      font-family: "Material Icons";
      font-size: 16px;
      position: relative;
      top: 3px;
    }
    &.valid {
      &::before {
        content: "\E86C";
        color: $green;
      }
    }
  }
  &.error {
    li {
      color: $secondary-brand-color;
    }
  }
}
.form-control-custom,
input.profile__datepicker {
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  height: 32px;
  padding-left: 10px;
  width: 100%;
  &:focus {
    border-color: #0077bf;
  }
}

.custom-select {
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #bfbebe;
  border-radius: 2px;
  background: #fff url("./../../assets/images/dropdown.svg") right 15px center
    no-repeat;
  -webkit-appearance: none;
  height: 32px;
  padding-right: 30px;
  &:focus {
    border-color: #0077bf;
  }
}
.status-pill {
  padding: 2px 15px;
  color: #003edf;
  background: #f7f9ff;
  border: 1px solid #003edf;
  border-radius: 15px;
  display: inline-block;
  position: relative;
  font-size: 13px;

  &.approved {
    // #00a558
    color: #00a558;
    background: #e9f9f2;
    border: 1px solid #00a558;
    padding: 2px 10px;
// pending #f5a91a 
   
  }
  &.pending {
    color: #FEB600;
    background: rgba(245, 169, 26, 0.1);
    border: 1px solid #f5a91a;
    padding: 2px 10px;
  }

  &.reject {
    color: #f93d49;
    background: rgba(249, 61, 73, 0.1);
    border: 1px solid #f93d49;
    font-size: 13px;
    padding: 2px 10px;
  }

  &.Void {
    color: #f5a91a;
    background: rgba(245, 169, 26, 0.1);
    border: 1px solid #f5a91a;
  }

  span {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 31px;
    left: 0;
    min-width: 250px;
    background: white;
    padding: 5px 10px;
    border: 1px solid;
    border-radius: 5px;
    transition: all 0.3s ease;
    z-index: 2;
  }

  &:hover {
    span {
      opacity: 1;
      visibility: visible;
    }
  }
}

p.form-group-error {
  color: red;
  font-weight: normal;
  padding-top: 5px;
  text-align: left;
}

button {
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
.pos-relative {
  position: relative;
}
.success-message {
  color: #00a558 !important;
}
.error-message {
  color: red !important;
}
.border-bottom-1 {
  border-bottom: 1px solid #dbdbdb;
}
.p-0 {
  padding: 0 !important;
}
.line-height-35 {
  line-height: 35px;
}
.line-1 {
  width: 100%;
  height: 0px;
  opacity: 0.2;
  border-bottom: 1px solid #000000;
}
.modal-custom {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    .modal-container {
      position: absolute;
      max-width: 500px;
      background: #ffffff;
      border-radius: 10px;
      margin: auto;
      transform: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .modal-header {
        padding: 10px 25px;

        border-bottom: 1px solid #e5e5e5;
        h3 {
          color: #211f1f;
          position: relative;
          top: 5px;
          font-size: 16px;
          font-weight: 500;
        }
        .modal-close {
          img {
            cursor: pointer;
          }
        }
      }
      .modal-body {
        padding: 20px 25px;
        .form-group {
          margin-bottom: 20px;
          label {
            padding-bottom: 10px;
          }
          input,
          select {
            font-weight: 500;
            color: #363636;
            font-size: 14px;
          }
        }
        .btn {
          height: 35px;
          &.mw-100 {
            min-width: 100px;
          }
        }
      }
    }
  }
}
.add-more-button,
.remove-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #f93d49;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid #f93d49;
  }
  &:hover + label:before {
    opacity: 0.9;
  }
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  &:checked + label:before {
    background: #f35429;
  }
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.no-records {
  // border: 1px solid #dbdbdb;
  padding: 30px;
  text-align: center;
  background: #fff;
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 19px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: #00a558;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #00a558;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d3d3d3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 4px;
      bottom: 4px;
      top: 4px;
      background-color: white;
      transition: 0.4s;
    }
    &.round {
      border-radius: 24px;
      &::before {
        border-radius: 50%;
      }
    }
  }
}

.table-body {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  // margin-right: 17px;
}

.error {
  color: red !important;
  font-weight: normal;
}

.no-record {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-size: 16px;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none !important;
}

.no-records,
.no-permission {
  // border: 1px solid #dbdbdb;
  padding: 30px;
  text-align: center;
  background: #fff;
}
.label.not-allowed {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.pos-absolute {
  position: absolute;
}
.nowrap {
  white-space: nowrap;
}
.formula-title {
  font-size: 18px;
}
.transaction-container {
  .modal-container {
    max-width: 422px !important;
  }
}
.header-bg {
  background: #f7fcff;
  border-radius: 5px 5px 0 0;
}
