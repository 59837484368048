.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($black, 0.2);
    transition: all 0.1s ease;

    &-container {
        background: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    //Login Modal
    &--login {
        .modal-container {
            width: 345px;
            border-radius: 3px;
            padding: 40px;
            opacity: 0;
            visibility: hidden;
            .close-icon {
                position: absolute;
                display: inline-block;
                top: 15px;
                right: 20px;
                cursor: pointer;
            }
            p {
                letter-spacing: 0.005em;
            }
        }
    }
}

.modal {
    &.open {
        opacity: 1;
        visibility: visible;
        .modal-container {
            animation: loginOpenAnimation 0.1s ease-in-out 0.3s forwards;
        }
    }
    &.close {
        animation: fadeOut 0.1s ease-in-out 0.1s forwards;
        .modal-container {
            animation: loginCloseAnimation 0.1s ease-in-out;
        }
    }
}

@keyframes loginOpenAnimation {
    0% {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -100%);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(-50%, -50%);
    }
}

@keyframes loginCloseAnimation {
    0% {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -100%);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
